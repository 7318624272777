import { Button, Modal } from "react-bootstrap";

const ConfirmModal: React.FC<{
  text: string;
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = ({ text, show, onClose, onConfirm }) => (
  <Modal show={show} onHide={onClose} size="sm">
    <Modal.Header closeButton>
      <Modal.Title>{text}</Modal.Title>
    </Modal.Header>
    <Modal.Footer>
      <Button variant="secondary" onClick={onClose}>
        No
      </Button>
      <Button variant="danger" onClick={onConfirm}>
        Yes
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ConfirmModal;

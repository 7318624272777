import {
  Category,
  CreateCategoryDTO,
  CreateLifeEventDTO,
  LifeEvent,
  UpdateCategoryDTO,
  UpdateEventDTO,
  User,
} from "~/types";

export interface CreateEventResponse {
  success: boolean;
  event: LifeEvent;
}

export interface UpdateEventResponse {
  success: boolean;
  event: LifeEvent;
}

export interface CreateCategoryResponse {
  success: boolean;
  categories: Category[];
}

export interface UpdateCategoryResponse {
  success: boolean;
  category: Category;
}

export type ErrorCode = "EVENT.INVALID_DATE";

export const API_URL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:3000/api";

export const api = {
  profile: `${API_URL}/profile`,
  authEmail: `${API_URL}/auth/email`,
  authVerify: `${API_URL}/auth/email/verify`,
  events: `${API_URL}/events`,
  categories: `${API_URL}/categories`,
};

export const errors: Record<ErrorCode, string> = {
  "EVENT.INVALID_DATE": "Invalid event date",
};

export async function authEmail(email: string) {
  const response = await fetch(api.authEmail, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  });

  if (response.ok) {
    return response.json();
  } else if (response.status === 429) {
    throw new Error("Too many requests. Try again later");
  } else throw new Error("Incorrect email");
}

export async function authVerify(email: string, code: string) {
  const response = await fetch(api.authVerify, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({ email, code }),
  });

  if (response.ok) {
    return response.json();
  } else if (response.status === 429) {
    throw new Error("Too many requests. Try again later");
  } else throw new Error("Incorrect code");
}

// export async function getProfile(name: string, birthDate: string) {
export async function getProfile() {
  const response = await fetch(api.profile, {
    credentials: "include",
  });

  if (response.ok) {
    return response.json();
  } else if (response.status === 401) {
    throw new Error("Unauthorized");
  } else throw new Error("Profile fetching error");
}

export async function setProfile({
  name,
  birthDate,
  isTutorialCompleted,
}: Partial<User>) {
  const response = await fetch(api.profile, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({
      name,
      birthDate: birthDate?.toISOString(),
      isTutorialCompleted,
    }),
  });

  const json = await response.json().catch((_) => {
    throw new Error("Request error");
  });
  if (response.ok) {
    return json;
  } else {
    const errorCode = json?.message as ErrorCode;
    throw new Error(errors[errorCode] || "Incorrect data");
  }
}

export async function getCategories() {
  const response = await fetch(api.categories, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  const json = await response.json().catch((_) => {
    throw new Error("Request error");
  });
  if (response.ok) {
    return json;
  } else {
    const errorCode = json?.message as ErrorCode;
    throw new Error(errors[errorCode] || "Cannot get categories");
  }
}

export async function createCategory(
  category: CreateCategoryDTO
): Promise<CreateCategoryResponse> {
  const response = await fetch(api.categories, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(category),
  });

  const json = await response.json().catch((_) => {
    throw new Error("Request error");
  });
  if (response.ok) {
    return json;
  } else {
    const errorCode = json?.message as ErrorCode;
    throw new Error(errors[errorCode] || "Can't create category");
  }
}

export async function updateCategory(
  data: UpdateCategoryDTO,
  id: string
): Promise<UpdateCategoryResponse> {
  const response = await fetch(`${api.categories}/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(data),
  });

  const json = await response.json().catch((_) => {
    throw new Error("Request error");
  });
  if (response.ok) {
    return json;
  } else {
    const errorCode = json?.message as ErrorCode;
    throw new Error(errors[errorCode] || "Can't update category");
  }
}

export async function deleteCategory(id: string): Promise<void> {
  const response = await fetch(`${api.categories}/${id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  const json = await response.json().catch((_) => {
    throw new Error("Request error");
  });
  if (response.ok) {
    return json;
  } else {
    const errorCode = json?.message as ErrorCode;
    throw new Error(errors[errorCode] || "Can't delete category");
  }
}

export async function getEvents(dateFrom: string, dateTo: string) {
  const query = new URLSearchParams({ dateFrom, dateTo }).toString();
  const response = await fetch(`${api.events}?${query}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  const json = await response.json().catch((_) => {
    throw new Error("Request error");
  });
  if (response.ok) {
    return json;
  } else {
    const errorCode = json?.message as ErrorCode;
    throw new Error(errors[errorCode] || "Cannot get events");
  }
}

export async function createEvent(
  event: CreateLifeEventDTO
): Promise<CreateEventResponse> {
  const response = await fetch(api.events, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(event),
  });

  const json = await response.json().catch((_) => {
    throw new Error("Request error");
  });
  if (response.ok) {
    return json;
  } else {
    const errorCode = json?.message as ErrorCode;
    throw new Error(errors[errorCode] || "Incorrect data");
  }
}

export async function updateEvent(
  data: UpdateEventDTO,
  id: string
): Promise<UpdateEventResponse> {
  const response = await fetch(`${api.events}/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(data),
  });

  const json = await response.json().catch((_) => {
    throw new Error("Request error");
  });
  if (response.ok) {
    return json;
  } else {
    const errorCode = json?.message as ErrorCode;
    throw new Error(errors[errorCode] || "Incorrect data");
  }
}

export async function deleteEvent(id: string): Promise<void> {
  const response = await fetch(`${api.events}/${id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  const json = await response.json().catch((_) => {
    throw new Error("Request error");
  });
  if (response.ok) {
    return json;
  } else {
    const errorCode = json?.message as ErrorCode;
    throw new Error(errors[errorCode] || "Can't delete event");
  }
}

export default api;

import { ReactEventHandler, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Button,
  ButtonGroup,
  Form,
  Modal,
  ToggleButton,
} from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import styled, { CSSProperties } from "styled-components";
import { useCategoryStore, useEventStore, useUserStore } from "~/store";
import { LifeEvent } from "~/types";
import ConfirmModal from "~/components/ConfirmModal";

interface EditEventModalProps {
  event: LifeEvent;
  onClose: () => void;
}

const ModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

function EditEventModal({ event, onClose }: EditEventModalProps) {
  const [title, setTitle] = useState<LifeEvent["title"]>(event.title);
  const [note, setNote] = useState<LifeEvent["note"]>(event.note);
  const [priority, setPriority] = useState<LifeEvent["priority"]>(
    event.priority
  );
  const [isPeriod, setPeriod] = useState(!!event.dateEnd);
  const [dateStart, setDateStart] = useState<LifeEvent["dateStart"]>(
    event.dateStart
  );
  const [dateEnd, setDateEnd] = useState<LifeEvent["dateEnd"]>(event.dateEnd);
  const [categoryId, setCategoryId] = useState<LifeEvent["categoryId"]>(
    event.categoryId
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const birthDate = useUserStore((state) => state.user?.birthDate);
  const categories = useCategoryStore((state) => state.categories);
  const updateEvent = useEventStore((state) => state.update);
  const deleteEvent = useEventStore((state) => state.delete);

  useEffect(() => {
    if (categories.length === 1) setCategoryId(categories[0].id);
  }, [categories]);

  const onDateStartChange = (date: Date | null) => date && setDateStart(date);
  const onDateEndChange = (date: Date | null) => date && setDateEnd(date);

  const onFormSubmit: ReactEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const defaultCategoryId =
      categories.find((category) => category.isDefault)?.id || "";

    updateEvent(
      {
        title,
        note,
        priority,
        dateStart: { date: dateStart },
        dateEnd: dateEnd && { date: dateEnd },
        category: categoryId ?? defaultCategoryId,
      },
      event.id
    )
      .then(() => {
        onClose();
      })
      .catch((error) => setErrorMessage(error.message));
  };

  const onDelete = () => {
    deleteEvent(event.id).then(() => onClose());
  };

  return (
    <Modal contentClassName="event-modal" show={true} onHide={onClose} centered>
      <Form onSubmit={onFormSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Event</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <Row>
            <span>Title</span>
            <TextField
              required
              type="text"
              label="Event title"
              autoFocus
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            />
          </Row>
          <Row>
            <span>Note</span>
            <TextField
              type="text"
              label="Event note"
              value={note}
              onChange={(event) => setNote(event.target.value)}
            />
          </Row>
          <Row>
            <span>Priority</span>
            <TextField
              type="number"
              label="Event note"
              value={priority}
              onChange={(event) =>
                setPriority(parseInt(event.target.value, 10) ?? priority)
              }
            />
          </Row>
          <Row>
            <span>Period</span>
            <BootstrapSwitchButton
              size="sm"
              checked={isPeriod}
              onlabel="On"
              offlabel="Off"
              onChange={(checked) => setPeriod(checked)}
            />
          </Row>
          <Row>
            <span>{isPeriod ? "Start" : ""} Date</span>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Pick Date"
                disableHighlightToday
                minDate={birthDate}
                maxDate={new Date()}
                inputFormat="dd.MM.yyyy"
                value={dateStart}
                onChange={onDateStartChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Row>
          {isPeriod ? (
            <Row>
              <span>End Date</span>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Pick Date"
                  disableHighlightToday={true}
                  minDate={birthDate}
                  maxDate={new Date()}
                  inputFormat="dd.MM.yyyy"
                  value={dateEnd}
                  onChange={onDateEndChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Row>
          ) : null}
          <Row>
            <span style={{ marginRight: "auto" }}>Categories</span>
            <ButtonGroup>
              {categories.map((category) => {
                const { id, color, title } = category;
                const style: CSSProperties = { borderColor: `#${color}` };
                const isChecked = categoryId === id;

                if (isChecked) style.backgroundColor = `#${color}`;

                return (
                  <ToggleButton
                    style={style}
                    key={id}
                    id={id}
                    type="radio"
                    value={id}
                    checked={isChecked}
                    onChange={(e) => setCategoryId(e.currentTarget.value)}
                  >
                    {title}
                  </ToggleButton>
                );
              })}
            </ButtonGroup>
          </Row>
          {errorMessage ? <div className="error">{errorMessage}</div> : null}
        </ModalBody>
        <Modal.Footer>
          <Button
            type="button"
            variant="danger"
            onClick={() => setShowConfirmModal(true)}
          >
            Delete
          </Button>
          <Button type="submit" variant="primary">
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
      <ConfirmModal
        text="Delete event?"
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={onDelete}
      />
    </Modal>
  );
}

export default EditEventModal;

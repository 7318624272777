import { useEffect, useState } from "react";
import shallow from "zustand/shallow";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Navigate, Outlet } from "react-router-dom";
import { useUserStore } from "~/store";

function Main() {
  const [isFetched, setFetched] = useState(false);
  const { user, fetchUser, logout } = useUserStore(
    (state) => ({
      user: state.user,
      fetchUser: state.fetch,
      logout: state.logout,
    }),
    shallow
  );

  useEffect(() => {
    fetchUser().finally(() => setFetched(true));
  }, [fetchUser]);

  console.log("user =>", user);

  return isFetched && !user ? (
    <Navigate to="/login" replace />
  ) : (
    <div>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#">Lifeline</Navbar.Brand>
          <Nav className="justify-content-end">
            <Nav.Link onClick={logout}>Logout</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container fluid>
        <Outlet />
      </Container>
    </div>
  );
}

export default Main;

import {
  ChangeEventHandler,
  ReactEventHandler,
  useEffect,
  useState,
} from "react";
import { Button, Container, Navbar } from "react-bootstrap";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { useUserStore } from "~/store";
import { useNavigate } from "react-router-dom";
import { FormControlElement } from "~/types";

const FormContainer = styled.div`
  width: 100%;
  max-width: 440px;
  padding: 40px;
  background-color: #edf5ff;
  border: 1px solid #0d3b7e;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 15vh;

  button {
    width: 100%;
  }

  input {
    margin-bottom: 4px;
  }

  p {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;

function Auth() {
  const [isCodeSent, setCodeSent] = useState(false);
  const [codeSentTimestamp, setCodeSentTimestamp] = useState<number>();
  const [retryTimeRemaining, setRetryTimeRemaining] = useState<number>(0);
  const [refreshFlag, setRefreshFlag] = useState<number>();
  const [isEmailValid, setEmailValid] = useState(false);
  const [isCodeValid, setCodeValid] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [codeValue, setCodeValue] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [codeErrorMessage, setCodeErrorMessage] = useState("");

  const navigate = useNavigate();
  const { user, auth, verify } = useUserStore();

  useEffect(() => {
    if (codeSentTimestamp) {
      const remaining =
        10 -
        (Math.floor(Date.now() / 1000) - Math.floor(codeSentTimestamp / 1000));
      if (remaining > 0) {
        setRetryTimeRemaining(remaining);
        const timeout = setTimeout(() => setRefreshFlag(Date.now()), 1000);
        return () => clearTimeout(timeout);
      } else {
        setRetryTimeRemaining(0);
        setCodeSentTimestamp(undefined);
      }
    }
  }, [
    refreshFlag,
    codeSentTimestamp,
    setCodeSentTimestamp,
    setRetryTimeRemaining,
  ]);

  useEffect(() => {
    if (user && user.id) {
      navigate("/profile");
    }
  }, [user, navigate]);

  const onEmailChange: ChangeEventHandler<FormControlElement> = ({
    target,
  }) => {
    setEmailValid(target.checkValidity());
    setEmailValue(target.value.trim());
  };

  const onEmailSubmit: ReactEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setCodeSentTimestamp(Date.now());
    auth(emailValue)
      .then(() => {
        setCodeSent(true);
        setEmailErrorMessage("");
      })
      .catch((error) => setEmailErrorMessage(error.message));
  };

  const onCodeChange: ChangeEventHandler<FormControlElement> = ({ target }) => {
    setCodeValid(target.checkValidity());
    setCodeValue(target.value.trim());
  };

  const onCodeSubmit: ReactEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    verify(emailValue, codeValue)
      .then(() => setCodeErrorMessage(""))
      .catch((error) => setCodeErrorMessage(error.message));
  };

  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#">Lifeline</Navbar.Brand>
        </Container>
      </Navbar>
      <Container>
        <FormContainer>
          <Form onSubmit={onEmailSubmit}>
            <Form.Group className="mb-3" controlId="formEmail">
              <p>Type your email and we will send you a verification code</p>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter email"
                value={emailValue}
                onChange={onEmailChange}
                autoFocus
              />
              {emailErrorMessage ? (
                <div className="error">{emailErrorMessage}</div>
              ) : null}
            </Form.Group>
            <Button
              type="submit"
              variant="primary"
              disabled={!isEmailValid || retryTimeRemaining > 0}
            >
              Send code{" "}
              {retryTimeRemaining > 0 ? `(retry in ${retryTimeRemaining})` : ""}
            </Button>
          </Form>
          {isCodeSent && (
            <Form onSubmit={onCodeSubmit}>
              <Form.Group className="mb-3" controlId="formCode">
                <p></p>
                <p>Verification code has been sent to {emailValue}</p>
                <Form.Label>Verification code</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder="Enter code"
                  value={codeValue}
                  onChange={onCodeChange}
                  autoFocus
                />
                {codeErrorMessage ? (
                  <div className="error">{codeErrorMessage}</div>
                ) : null}
              </Form.Group>
              <div className="d-flex">
                <Button type="submit" variant="primary" disabled={!isCodeValid}>
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </FormContainer>
      </Container>
    </div>
  );
}

export default Auth;

import { lighten } from "@mui/material";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import styled from "styled-components";
import { Category } from "~/types";

type ChartTooltipPayload = Payload<ValueType, NameType>;

interface ChartTooltipProps {
  active?: boolean;
  payload?: ChartTooltipPayload[];
  categories: Category[];
}

interface TooltipItemProps {
  name?: string;
  value?: ValueType;
  date?: string;
  category?: Category;
}

const TooltipWrapper = styled.div<{ color: string }>`
  margin-bottom: 10px;
  padding: 10px;
  background: ${({ color }) => lighten(color, 0.7)};

  header {
    display: flex;
    flex-direction: row;
  }

  .title {
    margin: 0;
    padding: 0;
    line-height: 0.9;
    font-size: 24px;
  }

  .mood {
    padding-left: 16px;
  }

  .mood-container {
    position: relative;
    top: -4px;
    right: -4px;
    display: flex;
    width: 28px;
    height: 28px;

    justify-content: center;
    border-radius: 50%;
    border: 2px solid #000000;
    color: #000000;
    line-height: 24px;
  }
`;

const TooltipItem: React.FC<TooltipItemProps> = ({
  name,
  value,
  date,
  category,
}) => {
  return (
    <TooltipWrapper color={category ? `#${category?.color}` : "#cccccc"}>
      <header>
        <h5 className="title">{name}</h5>
        <div className="mood">
          <div className="mood-container">{value}</div>
        </div>
      </header>
      <sub className="text-secondary tooltip-date">{date}</sub>
    </TooltipWrapper>
  );
};

const ChartTooltip: React.FC<ChartTooltipProps> = ({
  active,
  payload,
  categories,
}) => {
  return (
    <div>
      {active && payload?.length
        ? payload
            .filter((item) => item.name !== "date")
            .map((item, i) => (
              <TooltipItem
                key={`${item.value?.toString()}${i}`}
                value={item.value}
                name={item.payload?.name}
                date={new Date(item.payload?.date).toLocaleString()}
                category={categories.find(
                  (category) => category.id === item.name
                )}
              />
            ))
        : null}
    </div>
  );
};

export default ChartTooltip;

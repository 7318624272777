import { ChangeEventHandler, ReactEventHandler, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { FormControlElement } from "~/types";
import { useUserStore } from "~/store";

function Onboard() {
  const [nameValue, setNameValue] = useState("");
  const [birthDateValue, setBirthDateValue] = useState<Date>();
  const [isNameValid, setNameValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isSubmitBtnDisabled = !isNameValid || !birthDateValue || !!errorMessage;

  const updateUser = useUserStore((state) => state.update);

  const onBirthDateChange = (date: Date | null) =>
    date && setBirthDateValue(date);
  const onBirthDateError = (error: any) =>
    setErrorMessage(error ? "Incorrect date" : "");

  const onNameChange: ChangeEventHandler<FormControlElement> = ({ target }) => {
    setNameValid(target.checkValidity());
    setNameValue(target.value.trim());
  };

  const onProfileSubmit: ReactEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (!nameValue || !birthDateValue) return;

    updateUser({
      name: nameValue,
      birthDate: birthDateValue,
    })
      .then(() => {
        setErrorMessage("");
      })
      .catch((error: Error) => setErrorMessage(error.message));
  };

  return (
    <div className="onboard">
      <Form onSubmit={onProfileSubmit}>
        <Form.Group className="mb-3" controlId="formProfile">
          <Form.Label>Your name</Form.Label>
          <Form.Control
            required
            className="mb-3"
            type="text"
            placeholder="Enter your name"
            autoFocus
            value={nameValue}
            onChange={onNameChange}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Birth date"
              disableHighlightToday
              maxDate={new Date()}
              inputFormat="dd.MM.yyyy"
              value={birthDateValue}
              onChange={onBirthDateChange}
              onError={onBirthDateError}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
          {errorMessage ? (
            <div className="error mt-1">{errorMessage}</div>
          ) : null}
        </Form.Group>
        <Button type="submit" variant="primary" disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default Onboard;

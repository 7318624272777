import { Checkbox, TextField } from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import { ReactEventHandler, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import styled from "styled-components";
import ConfirmModal from "~/components/ConfirmModal";
import { useCategoryStore } from "~/store";
import { Category } from "~/types";

interface EditCategoryModalProps {
  category: Category;
  onClose: () => void;
}

const ModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

function EditCategoryModal({ category, onClose }: EditCategoryModalProps) {
  const [title, setTitle] = useState(category.title);
  const [color, setColor] = useState(category.color);
  const [isDefault, setDefault] = useState(category.isDefault);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const updateCategory = useCategoryStore((state) => state.update);
  const deleteCategory = useCategoryStore((state) => state.delete);

  const onFormSubmit: ReactEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    updateCategory({ title, color, isDefault }, category.id)
      .then(() => onClose())
      .catch((error) => setErrorMessage(error.message));
  };

  const onDelete = () => {
    deleteCategory(category.id).then(() => onClose());
  };

  return (
    <Modal show={true} onHide={onClose} centered>
      <Form onSubmit={onFormSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{title} - edit</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <Row>
            <span>Title</span>
            <TextField
              required
              type="text"
              label="Category title"
              autoFocus
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            />
          </Row>
          <Row>
            <span>Color</span>
            <MuiColorInput
              style={{ maxWidth: 228 }}
              label="Category color"
              format="hex"
              isAlphaHidden={true}
              value={color[0] === "#" ? color : `#${color}`}
              onChange={(value) => setColor(value.toString())}
            />
          </Row>
          <Row>
            <span>Default?</span>
            <Checkbox
              checked={isDefault}
              onChange={(event) => setDefault(event.target.checked)}
            />
          </Row>
          {errorMessage ? <div className="error">{errorMessage}</div> : null}
        </ModalBody>
        <Modal.Footer>
          <Button
            type="button"
            variant="danger"
            onClick={() => setShowConfirmModal(true)}
          >
            Delete
          </Button>
          <Button type="submit" variant="primary">
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
      <ConfirmModal
        text="Delete category?"
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={onDelete}
      />
    </Modal>
  );
}

export default EditCategoryModal;

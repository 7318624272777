import create from "zustand";
import {
  authEmail,
  authVerify,
  createCategory,
  createEvent,
  deleteCategory,
  deleteEvent,
  getCategories,
  getEvents,
  getProfile,
  setProfile,
  updateCategory,
  updateEvent,
} from "./services/api";
import {
  User,
  Category,
  LifeEvent,
  CreateLifeEventDTO,
  CreateCategoryDTO,
  UpdateCategoryDTO,
  UpdateEventDTO,
} from "./types";

type AuthResponse = any;
type VerifyResponse = any;
type UserFetchResponse = void;
type UserUpdateResponse = any;
type CategoryFetchResponse = void;
type EventFetchResponse = void;

type Modal =
  | "none"
  | "createEvent"
  | "editEvent"
  | "createCategory"
  | "editCategory";
type EventCreateParams = { date: Date };
type EventEditParams = { event?: LifeEvent };
type CategoryEditParams = { category?: Category };

interface UserState {
  user?: User;
  fetch: () => Promise<UserFetchResponse>;
  update: (data: Partial<User>) => Promise<UserUpdateResponse>;
  auth: (email: string) => Promise<AuthResponse>;
  verify: (email: string, code: string) => Promise<VerifyResponse>;
  logout: () => void;
}

interface CategoryState {
  categories: Category[];
  fetch: () => Promise<CategoryFetchResponse>;
  create: (event: CreateCategoryDTO) => Promise<void>;
  update: (data: UpdateCategoryDTO, id: string) => Promise<void>;
  delete: (id: string) => Promise<void>;
}

interface EventState {
  events: LifeEvent[];
  fetch: (from: Date, to: Date) => Promise<EventFetchResponse>;
  create: (event: CreateLifeEventDTO) => Promise<void>;
  update: (data: UpdateEventDTO, id: string) => Promise<void>;
  delete: (id: string) => Promise<void>;
}

interface ModalState {
  modal: Modal;
  params: {
    createEvent: EventCreateParams;
    editEvent: EventEditParams;
    editCategory: CategoryEditParams;
  };
  openEventCreateModal: (params?: EventCreateParams) => void;
  openEventEditModal: (params: EventEditParams) => void;
  openCategoryCreateModal: () => void;
  openCategoryEditModal: (params: CategoryEditParams) => void;
  closeModal: () => void;
}

export const useUserStore = create<UserState>((set) => ({
  user: undefined,
  fetch: () => getProfile().then(({ user }) => set({ user })),
  update: (data) => setProfile(data).then(({ user }) => set({ user })),
  auth: (email: string) =>
    authEmail(email).catch((error) => {
      set({ user: undefined });
      throw error;
    }),
  verify: (email: string, code: string) =>
    authVerify(email, code)
      .then(({ user }) => {
        console.log("user =>", user);
        set({ user });
      })
      .catch((error) => {
        set({ user: undefined });
        throw error;
      }),
  logout: () => set({ user: undefined }),
}));

export const useCategoryStore = create<CategoryState>((set, get) => ({
  categories: [],
  fetch: async () => {
    getCategories().then(({ categories }) => {
      console.log("categories =>", categories);
      set({ categories });
    });
  },
  create: async (newCategory: CreateCategoryDTO) =>
    createCategory(newCategory).then(({ categories: createdCategories }) => {
      const createdCategory = createdCategories[0];
      console.log("add category result =>", createdCategory);
      const oldCategories = get().categories;
      set({ categories: [...oldCategories, createdCategory] });
    }),
  update: async (data, id) =>
    updateCategory(data, id).then(({ category }) => {
      console.log("update category result =>", category);
      const oldCategories = get().categories;
      set({
        categories: [
          ...oldCategories.filter((c) => c.id !== category.id),
          category,
        ].sort((a, b) => a.position - b.position),
      });
    }),
  delete: async (id) =>
    deleteCategory(id).then(() => {
      const oldCategories = get().categories;
      set({
        categories: oldCategories.filter((c) => c.id !== id),
      });
    }),
}));

const deserializeEvent = (event: any) => ({
  ...event,
  dateStart: new Date(event.dateStart),
  dateEnd: event.dateEnd && new Date(event.dateEnd),
});

export const useEventStore = create<EventState>((set, get) => ({
  events: [],
  fetch: async (from: Date, to: Date) => {
    getEvents(from.toISOString(), to.toISOString())
      .then(({ events }) => {
        set({ events: events.map(deserializeEvent) });
      })
      .catch((e) => {
        throw e;
      });
  },
  create: async (newEvent: CreateLifeEventDTO) =>
    createEvent(newEvent).then(({ event: createdEvent }) => {
      console.log("add event result =>", createdEvent);
      const oldEvents = get().events;
      set({ events: [...oldEvents, deserializeEvent(createdEvent)] });
    }),
  update: async (data, id) =>
    updateEvent(data, id).then(({ event }) => {
      console.log("update event result =>", event);
      const oldEvents = get().events;
      set({
        events: [
          ...oldEvents.filter((e) => e.id !== event.id),
          deserializeEvent(event),
        ],
      });
    }),
  delete: async (id) =>
    deleteEvent(id).then(() => {
      const oldEvents = get().events;
      set({
        events: oldEvents.filter((e) => e.id !== id),
      });
    }),
}));

export const useModalStore = create<ModalState>((set, get) => ({
  modal: "none",
  params: {
    createEvent: { date: new Date() },
    editEvent: { event: undefined },
    editCategory: { category: undefined },
  },
  openEventCreateModal: (params: EventCreateParams = { date: new Date() }) =>
    set({
      modal: "createEvent",
      params: { ...get().params, createEvent: params },
    }),
  openEventEditModal: (params: EventEditParams) =>
    set({
      modal: "editEvent",
      params: { ...get().params, editEvent: params },
    }),
  openCategoryCreateModal: () =>
    set({
      modal: "createCategory",
    }),
  openCategoryEditModal: (params: CategoryEditParams) =>
    set({
      modal: "editCategory",
      params: { ...get().params, editCategory: params },
    }),
  closeModal: () => set({ modal: "none" }),
}));

import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import {
  useCategoryStore,
  useEventStore,
  useUserStore,
  useModalStore,
} from "~/store";
import { ReactComponent as ArrowIcon } from "~/assets/icons/arrow.svg";
import Onboard from "./components/Onboard";
import EditCategoryModal from "./components/EditCategoryModal";
import CreateEventModal from "./components/CreateEventModal";
import Chart from "./components/Chart";
import CreateCategoryModal from "./components/CreateCategoryModal";
import EditEventModal from "./components/EditEventModal";

const Page = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const Content = styled.main`
  width: 100%;
  min-height: 500px;
`;

const Sidebar = styled.aside`
  width: 250px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;

  .categories {
    li {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 24px;
      height: 24px;
      margin-bottom: 10px;

      span {
        cursor: pointer;
        padding-left: 8px;
      }

      .color {
        border-radius: 50%;
        height: 20px;
        width: 20px;
        cursor: pointer;
      }

      button {
        height: 100%;
        padding: 0 10px;
        margin-left: auto;
        display: flex;
        align-items: center;
      }
    }
  }
`;

function Profile() {
  const [isEditMode, setIsEditMode] = useState(true);

  const user = useUserStore((state) => state.user);
  const { categories, fetch: fetchCategories } = useCategoryStore();
  const fetchEvents = useEventStore((state) => state.fetch);

  useEffect(() => {
    if (user) {
      fetchCategories();
      fetchEvents(new Date(0), new Date());
    }
  }, [user, fetchCategories, fetchEvents]);

  const {
    modal,
    params,
    openEventCreateModal,
    openCategoryEditModal,
    openCategoryCreateModal,
    closeModal,
  } = useModalStore();

  if (!user || !categories) return null;

  return (
    <Page className="mt-3">
      <Content>
        {!user.birthDate ? <Onboard /> : <Chart categories={categories} />}
      </Content>
      <Sidebar>
        <ul className="categories">
          {categories.map((category) => {
            const { id, color, title } = category;
            const style = { backgroundColor: `#${color}` };
            return (
              <li key={id}>
                <div className="color" style={style}></div>
                <span>{title}</span>
                <button onClick={() => openCategoryEditModal({ category })}>
                  <ArrowIcon />
                </button>
              </li>
            );
          })}
          {categories.length < 5 && (
            <li onClick={() => openCategoryCreateModal()}>
              <div
                className="color"
                style={{
                  background: "transparent",
                  border: "2px dashed grey",
                }}
              ></div>
              <span>Add category</span>
              <button>
                <ArrowIcon />
              </button>
            </li>
          )}
        </ul>
        <Button
          variant="primary"
          disabled={!isEditMode}
          onClick={() => openEventCreateModal()}
        >
          Create Event
        </Button>
        <BootstrapSwitchButton
          checked={isEditMode}
          onlabel="Edit Mode"
          offlabel="View Mode"
          onChange={(checked) => setIsEditMode(checked)}
        />
      </Sidebar>
      {modal === "createEvent" && (
        <CreateEventModal date={params.createEvent.date} onClose={closeModal} />
      )}
      {modal === "editEvent" && params.editEvent.event ? (
        <EditEventModal event={params.editEvent.event} onClose={closeModal} />
      ) : null}
      {modal === "createCategory" && (
        <CreateCategoryModal onClose={closeModal} />
      )}
      {modal === "editCategory" && params.editCategory.category ? (
        <EditCategoryModal
          category={params.editCategory.category}
          onClose={closeModal}
        />
      ) : null}
    </Page>
  );
}

export default Profile;

import { Route, Routes } from "react-router-dom";
import Main from "./components/Main";
import Auth from "./pages/Auth";
import Profile from "./pages/Profile";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route path="profile" element={<Profile />} />
      </Route>
      <Route path="/login" element={<Auth />} />
    </Routes>
  );
}

export default App;

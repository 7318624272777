import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  LabelList,
  Dot,
  DotProps,
} from "recharts";
import styled from "styled-components";
import { useEventStore, useModalStore } from "~/store";
import { Category } from "~/types";
import ChartTooltip from "./ChartTooltip";

interface ChartProps {
  categories: Category[];
}

interface ChartXAxisDomain {
  left: number;
  right: number;
}

const ZOOM_DELTA_MS = 4000000000;
const ZOOM_LIMIT_MS = 86400000; // 24 hours

const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;
  user-select: none;
`;

const HoverableDot = styled(Dot)`
  stroke: white;
  stroke-width: 2px;
  &:hover {
    stroke: transparent;
    cursor: pointer;
  }
`;

function Chart({ categories }: ChartProps) {
  const events = useEventStore((state) => state.events);
  const [currentValue, setCurrentValue] = useState<number>(Date.now());
  const chartWrapperRef = useRef<HTMLDivElement>(null);
  const [domainX, setDomainX] = useState<ChartXAxisDomain>({
    left: NaN,
    right: NaN,
  });

  const openEventCreateModal = useModalStore(
    (state) => state.openEventCreateModal
  );
  const openEventEditModal = useModalStore((state) => state.openEventEditModal);

  const data = useMemo(
    () =>
      events
        .sort((a, b) => +a.dateStart - +b.dateStart)
        .map((event) => ({
          id: event.id,
          date: +event.dateStart,
          name: event.title,
          [event.categoryId]: event.priority,
        })),
    [events]
  );

  useEffect(() => {
    if (data && data.length) {
      const first = data[0].date;
      const last = data[data.length - 1].date;
      setDomainX({
        left: first,
        right: last,
      });
    }
  }, [data]);

  const getValueByPosition = (position: number) => {
    const { left, right } = domainX;
    if (chartWrapperRef.current && !isNaN(left) && !isNaN(right)) {
      const width = chartWrapperRef.current.clientWidth - 10;
      const x = position - 5;
      const value = Math.floor(left + (right - left) * (x / width));
      return value;
    }
  };

  const handleWheel = useCallback(
    (e: any) => {
      const direction = e.deltaY < 0 ? "top" : "bottom";
      const first = data[0].date;
      const last = data[data.length - 1].date;
      const current = currentValue;
      const domainLeft = !isNaN(domainX.left) ? domainX.left : first;
      const domainRight = !isNaN(domainX.right) ? domainX.right : last;
      const center = (current - domainLeft) / (domainRight - domainLeft);

      if (direction === "bottom") {
        const left = Math.max(
          Math.floor(domainLeft - ZOOM_DELTA_MS * center),
          first
        );
        const right = Math.min(
          Math.floor(domainRight + ZOOM_DELTA_MS * (1 - center)),
          last
        );
        setDomainX({
          left,
          right,
        });
      } else {
        const left = domainLeft + ZOOM_DELTA_MS * center;
        const right = domainRight - ZOOM_DELTA_MS * (1 - center);

        if (right - left < ZOOM_LIMIT_MS) {
          return;
        }

        setDomainX({
          left,
          right,
        });
      }
    },
    [data, domainX, currentValue]
  );

  const handleChartClick = useCallback(() => {
    openEventCreateModal({ date: new Date(currentValue) });
  }, [currentValue, openEventCreateModal]);

  type DotClickHandler = (
    event: MouseEvent<SVGCircleElement, globalThis.MouseEvent>,
    id: string
  ) => void;

  const handleDotClick: DotClickHandler = useCallback(
    (event, id) => {
      event.stopPropagation();
      openEventEditModal({ event: events.find((e) => e.id === id) });
    },
    [events, openEventEditModal]
  );

  return (
    <ChartWrapper onWheel={handleWheel} ref={chartWrapperRef}>
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          width={500}
          height={500}
          data={data}
          onClick={handleChartClick}
          onMouseMove={(state) => {
            if (state && state.chartX) {
              setCurrentValue(
                getValueByPosition(state.chartX) ??
                  Number(state.activeLabel || Date.now())
              );
            }
          }}
        >
          <Tooltip
            wrapperStyle={{ outline: 0 }}
            cursor={false}
            content={(props) => (
              <ChartTooltip categories={categories} {...props} />
            )}
          />
          <YAxis
            type="number"
            domain={["dataMin-1", "dataMax+1"]}
            allowDataOverflow
            mirror
            yAxisId="1"
          />

          <XAxis
            type="number"
            dataKey="date"
            domain={[domainX.left, domainX.right]}
            allowDataOverflow
            padding={{ left: 0, right: 0 }}
            tickFormatter={(timestamp) => new Date(timestamp).toDateString()}
          />
          {categories.map((category) => (
            <Scatter
              key={category.id}
              name={category.id}
              dataKey={category.id}
              fill={`#${category.color}`}
              yAxisId="1"
              line={
                <Line
                  connectNulls
                  key={category.id}
                  type="monotoneX"
                  dataKey={category.id}
                  stroke={`#${category.color}`}
                  fill="#ffffff"
                  strokeWidth={2}
                  yAxisId="1"
                  animationDuration={300}
                />
              }
              shape={
                <HoverableDot
                  r={6}
                  onClick={(props: DotProps & { id: string }, event) =>
                    handleDotClick(event, props.id)
                  }
                />
              }
            >
              <LabelList
                dataKey="name"
                position="top"
                fill="black"
                opacity={0.6}
              />
            </Scatter>
          ))}
        </ScatterChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
}

export default Chart;
